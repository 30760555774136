import Menu from "@components/Menu";
import { Title } from "@components/Typography";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import React from "react";
import FeaturesRoutes from "@src/routes/routes";
import { useFeatures } from "@components/Features/contexts";
import { Features } from "@components/Features";

const DataManagementSidebar: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isConditionSame } = useFeatures();

  const defaultPage = !isConditionSame(Features.EventSourcing)
    ? FeaturesRoutes.dataManagement.tags
    : FeaturesRoutes.dataManagement.activityStream.home;

  // remove the forward slash in the beginning
  const activePage = router.route || defaultPage;

  const handleMenuClick = (path: string) => {
    router.push(path);
  };

  const menuItems = [];

  if (isConditionSame(Features.IdentityStream)) {
    menuItems.push({
      key: FeaturesRoutes.dataManagement.identityStream.home,
      label: t("Identity stream"),
    });
  }

  if (isConditionSame(Features.ActivityStream)) {
    menuItems.push({
      key: FeaturesRoutes.dataManagement.activityStream.home,
      label: t("Activity stream"),
    });
  }

  if (isConditionSame(Features.UniqueIdentityStream)) {
    menuItems.push({
      key: FeaturesRoutes.dataManagement.uniqueIdentityStream.home,
      label: t("Unique identity stream"),
    });
  }

  if (isConditionSame(Features.InboundSettings)) {
    menuItems.push({
      key: FeaturesRoutes.dataManagement.sources.home,
      label: t("Data sources"),
    });
  }

  if (isConditionSame(Features.OutboundSettings)) {
    menuItems.push({
      key: FeaturesRoutes.dataManagement.destinations.home,
      label: t("Data destinations"),
    });
  }

  return (
    <div className="flex flex-col pt-10 font-sans">
      <Title className="px-5" level={4}>
        {t("Data management")}
      </Title>
      <Menu
        selectedKeys={[activePage]}
        mode="inline"
        style={{ background: "white" }}
        onClick={(info) => handleMenuClick(info.key)}
        items={menuItems}
      />
    </div>
  );
};

export default DataManagementSidebar;
