import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import Avatar from "@components/Avatar";
import Divider from "@components/Divider";
import { MenuItem } from "@components/Menu";
import Popover from "@components/Popover";
import { Text } from "@components/Typography";
import DisplayIf from "@components/Conditionals/DisplayIf";
import { useAuth } from "@src/features/auth/useAuth";
import FeaturesRoutes from "@src/routes/routes";
import React from "react";
import { useTranslation } from "react-i18next";

const AvatarPopover: React.FC = () => {
  const { t } = useTranslation();
  const { user, signOut } = useAuth();

  const renderUserName = () => {
    if (user["email"]) {
      return <Text>{user.email}</Text>;
    }

    return <Text>{user.username}</Text>;
  };

  const content = (
    <div className="w-full flex flex-col gap-3 w-60">
      <div className="flex gap-3 w-full items-center">
        <div>
          <DisplayIf condition={() => user.picture !== undefined} falsy={<Avatar size="large"><UserOutlined /></Avatar>}>
            <Avatar size="large" src={user.picture} />
          </DisplayIf>
        </div>
        <div className="flex flex-col w-[150px]">{renderUserName()}</div>
      </div>
      <MenuItem to={FeaturesRoutes.account.general} title={t("Account settings")} />
      <Divider noMargin />
      <MenuItem title={t("Log out")} suffixIcon={<LogoutOutlined />} onClick={signOut} />
    </div>
  );

  return (
    <Popover placement="bottomRight" className="cursor-pointer" trigger={["click"]} content={content}>
      <div>
        <DisplayIf condition={() => user.picture !== undefined} falsy={<Avatar size="small"><UserOutlined /></Avatar>}>
          <Avatar size="small" src={user.picture} />
        </DisplayIf>
      </div>
    </Popover>
  );
};

export default AvatarPopover;
