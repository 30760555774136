import React from "react";

interface ListSkeletonProps {
  rows?: number;
  size?: "default" | "small" | "large";
  noBorder?: boolean;
}

const ListSkeleton: React.FunctionComponent<ListSkeletonProps> = ({ rows = 5, size = "default", noBorder = false }) => {
  const renderRows = () => {
    const elements = [];

    const getRowHeight = () => {
      switch (size) {
        case "small":
          return "h-2";
        case "large":
          return "h-10";
        default:
          return "h-5";
      }
    };

    for (let i = 0; i < rows; i++) {
      elements.push(
        <div key={i} className="flex flex-col gap-y-4">
          <div className={`${getRowHeight()} bg-gray-200 w-full rounded-lg`} />
          {!noBorder && <div className="h-0.5 bg-gray-200 w-full" />}
        </div>,
      );
    }
    return elements;
  };

  return <div className="animate-pulse flex flex-col gap-y-4">{renderRows()}</div>;
};

export default ListSkeleton;
