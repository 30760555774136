const intToHex = (nr) => nr.toString(16).padStart(2, "0");

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const getExtension = (file) => {
  const re = /(?:\.([^.]+))?$/;
  const res = re.exec(file);
  return res ? res[1] : null;
};

export const interleave = (arr, thing: (index) => unknown) => [].concat(...arr.map((n, index) => [thing(index), n]));
export const parseFileNameForS3 = (filename: string) => {
  const uuid = Math.random().toString(36).slice(-6);
  const _extension = getExtension(filename);
  const _parsedFilename = filename
    .toLowerCase()
    .split(`.${_extension}`)[0]
    .replace(/[^a-z0-9]/g, "");
  const _filename = `${uuid}${_parsedFilename}`.substring(0, 60);
  return `${_filename}.${_extension}`;
};

export const getRandomString = (bytes) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * Encode a JSON to a Base64 string
 * @param obj A JSON object
 * @returns A Base64 string
 */
export const encodeJSON = (obj: object) => {
  if (!obj) return "";
  return Buffer.from(JSON.stringify(obj)).toString("base64");
};

/**
 * decode a Base64 string to a JSON object
 * @param obj A JSON object
 * @returns A Base64 string
 */
export const decodeJSON = (base64: string) => {
  if (!base64) return {};

  const buffer = Buffer.from(base64, "base64").toString("utf-8");

  if (buffer === "undefined") return {};

  return JSON.parse(buffer);
};

export const typeToLevel: Record<string, number> = {
  h1: 1,
  h2: 2,
  h3: 3,
  h4: 4,
  h5: 5,
};

export const sum = (array) => array.reduce((a, b) => a + b);

export const formatDate = (date) => new Date(date).toLocaleDateString();

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getAddressFromWalletId = (walletId: string) => {
  return walletId?.split(".")?.[1] || "";
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const capitalizeFirstLetter = (text: string) => {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const isNullOrEmpty = (maybeString?: string | number) => {
  return maybeString === null || maybeString === undefined || maybeString === "";
};

export const isNullOrEmptyObject = (maybeObject?: object) => {
  return maybeObject === null || maybeObject === undefined || Object.keys(maybeObject).length === 0;
};

export const isIframe = () => window.self !== window.top;

/**
 * Distribute probabilities evenly
 * @param n number of items
 * @returns array of probabilities
 */
export const distributeProbabilities = (n: number): number[] => {
  const probabilityPerItem = 1 / n;
  const probabilities: number[] = [];

  for (let i = 0; i < n; i++) {
    probabilities.push(probabilityPerItem);
  }

  return probabilities;
};

export const toChunksOfSize = <T>(array: T[], size: number): T[][] => {
  if (!array.length) {
    return [];
  }
  const head: T[] = array.slice(0, size);
  const tail: T[] = array.slice(size);

  return [head, ...toChunksOfSize(tail, size)].filter((_) => _.length > 0);
};
