import { DownOutlined } from "@ant-design/icons";
import { useAuth } from "@features/auth/useAuth";
import Button from "@components/Button";
import Divider from "@components/Divider";
import Popover from "@components/Popover";
import { Text, Title } from "@components/Typography";
import { useWorkspace } from "@src/hooks/useWorkspace";
import { notify } from "@src/services/toaster";
import { useTranslation } from "react-i18next";
import React from "react";
import { WorkspaceList } from "@features/workspaces/WorkspacePopoverButton/WorkspaceList.component";
import { useSystemSettingsQuery, WorkspaceDetails } from "@features/workspaces/settings/System/hooks/useSystemSettings";

export interface WorkspacePopoverButtonProps {
  id?: string;
}

const WorkspacePopoverButton: React.FC<WorkspacePopoverButtonProps> = () => {
  const { t } = useTranslation();
  const { workspaceId } = useAuth();
  const { data: workspaces, isLoading } = useSystemSettingsQuery();
  const { switchWorkspace } = useWorkspace();

  const currentWorkspace: WorkspaceDetails | undefined = workspaces?.find((w) => w.id === workspaceId);

  const handleSwitchWorkspace = async (selectedWorkspace: WorkspaceDetails) => {
    await switchWorkspace(selectedWorkspace);

    notify.success(`${t("Switched to workspace")}: ${selectedWorkspace.name}`);
  };

  const content = (
    <div className="w-full flex flex-col gap-3 w-60">
      <Title level={5}>{t("my_workspaces")}</Title>
      <WorkspaceList onSwitch={handleSwitchWorkspace} workspaces={workspaces} isLoading={isLoading} />
      <Divider noMargin />
    </div>
  );

  return (
    <Popover placement="bottomRight" className="cursor-pointer" trigger={["click"]} content={content}>
      <Button tooltip={currentWorkspace?.name} type="text">
        <Text className="w-20" ellipsis>
          {currentWorkspace?.name || t("...")}
        </Text>
        <DownOutlined />
      </Button>
    </Popover>
  );
};

export default WorkspacePopoverButton;
