import { useWorkspaceApi } from "@features/workspaces/hooks/useWorkspaceApi";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "@features/auth/useAuth";
import { WorkspaceResult } from "@src/pages/api/workspaces";
import { WorkspaceDetails } from "@features/workspaces/settings/System/hooks/useSystemSettings";

export const useWorkspacesQuery = () => {
  const { getUserWorkspaces } = useWorkspaceApi();

  return useQuery(["workspaces"], async () => {
    const workspaces: WorkspaceResult[] = (await getUserWorkspaces()) || [];

    // sort workspaces by alphabetical order
    workspaces.sort((a, b) => a.name?.localeCompare(b.name));

    return workspaces;
  });
};

export const useWorkspace = () => {
  const { setUserWorkspace, workspaceId } = useAuth();

  const switchWorkspace = async (selectedWorkspace: WorkspaceDetails) => {
    await setUserWorkspace(selectedWorkspace.id);
    // eslint-disable-next-line
    location.reload();
  };

  const getWorkspaceId = () => workspaceId;

  return {
    switchWorkspace,
    getWorkspaceId,
  };
};
