import { LoginOutlined } from "@ant-design/icons";
import Button from "@components/Button";
import FeaturesRoutes from "@src/routes/routes";
import MetacommerceLogo from "@components/MetacommerceLogo";
import WorkspacePopoverButton from "@src/features/workspaces/WorkspacePopoverButton";
import { useTranslation } from "react-i18next";
import { useAuth } from "@features/auth/useAuth";
import DisplayIf from "@components/Conditionals/DisplayIf";
import AvatarPopover from "./AvatarPopover";

const SignInButton = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-3">
      <Button type="primary" icon={<LoginOutlined />} href={FeaturesRoutes.login}>
        {t("Sign in")}
      </Button>
    </div>
  );
};
const LoggedInMenu = () => {
  return (
    <div className="flex items-center gap-3">
      <WorkspacePopoverButton />
      <AvatarPopover />
    </div>
  );
};

const Header = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="flex justify-between items-center py-4 px-4 sm:px-6 bg-white border-b">
      <MetacommerceLogo href={FeaturesRoutes.home} color="blue" />
      <div className="w-1/3">{/* <Search placeholder={t("navbar_search_placeholder")} className="w-full" /> */}</div>
      <DisplayIf condition={() => isAuthenticated} falsy={<SignInButton />}>
        <LoggedInMenu />
      </DisplayIf>
    </div>
  );
};

export default Header;
