import { DANGER_COLOR, SECONDARY_COLOR, SUCCESS_COLOR } from "@src/services/theme/colors";
import { Button as AntButton, Tooltip } from "antd";
import { ButtonType } from "antd/lib/button";
import React from "react";
import { ButtonProps } from "./Button.types";

const Button: React.FunctionComponent<ButtonProps> = ({ children, onClick, tooltip, type, style, ...props }) => {
  const getColorSettings = (): { type: ButtonType; style: any } => {
    switch (type) {
      case "primary":
        return {
          type: "primary",
          style: undefined,
        };
      case "secondary":
        return {
          type: "primary",
          style: { backgroundColor: SECONDARY_COLOR, border: "none" },
        };
      case "danger":
        return {
          type: "primary",
          style: { backgroundColor: DANGER_COLOR, border: "none" },
        };
      case "success":
        return {
          type: "primary",
          style: { backgroundColor: SUCCESS_COLOR, border: "none" },
        };
      default:
        return {
          type: type as ButtonType,
          style: undefined,
        };
    }
  };

  const colorSettings = getColorSettings();

  const button = (
    <AntButton {...props} type={colorSettings.type} style={{ ...style, ...colorSettings.style }} onClick={onClick}>
      {children}
    </AntButton>
  );

  if (tooltip) {
    return (
      <Tooltip className="w-full" title={tooltip}>
        {button}
      </Tooltip>
    );
  }

  return button;
};

export default Button;
