import React from 'react';
import { Divider as AntDivider } from "antd"
import { DividerProps } from './Divider.types';

const Divider: React.FC<DividerProps> = ({ noMargin, children, style, ...props }) => {
  return (
    <AntDivider {...props} style={noMargin ? { ...style, margin: 0 } : { ...style }}>
      {children}
    </AntDivider>
  )
};

export default Divider;