import accountRoutes from "@src/routes/account/routes";
import recoveryRoutes from "@src/routes/accountRecovery/routes";
import automationsRoutes from "@src/routes/automations/routes";
import dataManagementRoutes from "@src/routes/data-management/routes";
import identitiesRoutes from "@src/routes/identities/routes";
import marketingRoutes from "@src/routes/marketing/routes";
import workspacesRoutes from "@src/routes/workspace/routes";
import systemRoutes from "@src/routes/system/routes";

const FeaturesRoutes = {
  ...accountRoutes,
  ...recoveryRoutes,
  ...automationsRoutes,
  ...dataManagementRoutes,
  ...identitiesRoutes,
  ...marketingRoutes,
  ...workspacesRoutes,
  ...systemRoutes,
  home: "/",
  invites: (id: string) => {
    const params = new URLSearchParams({ id });

    return `/invites?${params.toString()}`;
  },
  login: "/login",
};

export default FeaturesRoutes;
