import Menu from "@components/Menu";
import { Title } from "@components/Typography";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import React from "react";
import FeaturesRoutes from "@src/routes/routes";
import { useFeatures } from "@components/Features/contexts";
import { Features } from "@components/Features";
import { ItemType } from "antd/lib/menu/hooks/useItems";

const AutomationsSidebar: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isConditionSame } = useFeatures();

  const defaultPage = !isConditionSame(Features.EventSourcing)
    ? FeaturesRoutes.automations.workflowLegacy.active
    : FeaturesRoutes.automations.workflows.home;

  const activePage = router.route || defaultPage;

  const handleMenuClick = (path: string) => {
    router.push(path);
  };
  const menuItems: ItemType[] = [];

  if (!isConditionSame(Features.EventSourcing)) {
    menuItems.push({
      key: "workflows",
      label: t("Workflows (Beta)"),
      style: { background: "#ffff" },
      children: [
        {
          key: FeaturesRoutes.automations.workflowLegacy.active,
          label: t("Active"),
        },
        {
          key: FeaturesRoutes.automations.workflowLegacy.paused,
          label: t("Paused"),
        },
      ],
    });
  }

  if (isConditionSame(Features.Workflow)) {
    menuItems.push({
      key: FeaturesRoutes.automations.workflows.home,
      label: t("Workflows"),
      style: { background: "#ffff" },
    });
  }

  return (
    <div className="flex flex-col pt-10 font-sans">
      <Title className="px-5" level={4}>
        {t("Automations")}
      </Title>
      <Menu
        selectedKeys={[activePage]}
        defaultOpenKeys={["workflows"]}
        mode="inline"
        style={{ background: "white" }}
        onClick={(info) => handleMenuClick(info.key)}
        items={menuItems}
      />
    </div>
  );
};

export default AutomationsSidebar;
