import React from "react";
import FeaturesRoutes from "@src/routes/routes";
import Menu from "@components/Menu";
import { Title } from "@components/Typography";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";

const SystemSidebar: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();

  // remove the forward slash in the beginning
  const activePage = router.route || FeaturesRoutes.workspaces.settings.name;

  const handleMenuClick = (info: { key: string }) => {
    router.push(info.key);
  };

  const menuItems = [
    {
      key: FeaturesRoutes.system.home,
      label: t("System"),
      onClick: handleMenuClick,
    },
    {
      key: FeaturesRoutes.system.workspaceList,
      label: t("Workspace List"),
      onClick: handleMenuClick,
    },
    {
      key: FeaturesRoutes.system.organizationList,
      label: t("Organization list"),
      onClick: handleMenuClick,
    },
  ];

  return (
    <div className="flex flex-col pt-10 font-sans gap-3">
      <Title className="px-5" level={4}>
        {t("Workspace")}
      </Title>
      <Menu selectedKeys={[activePage]} mode="inline" style={{ background: "white" }} items={menuItems} />
    </div>
  );
};

export default SystemSidebar;
