import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { SpinnerProps } from "./Spinner.types";

const Spinner: React.FunctionComponent<SpinnerProps> = ({ size = "default", className }) => {
  const getSizes = () => {
    if (size === "small") {
      return {
        fontSize: 12,
      };
    }

    if (size === "large") {
      return {
        fontSize: 36,
      };
    }

    return {
      fontSize: 24,
    };
  };

  const sizes = getSizes();

  const antIcon = <LoadingOutlined style={{ fontSize: sizes.fontSize }} spin />;

  return <Spin indicator={antIcon} className={className} />;
};

export default Spinner;
