const routes = {
  settings: {
    apiKeys: "/workspace/settings/api-keys",
    identities: "/workspace/settings/identities",
    uniqueIdentities: "/workspace/settings/unique-identities",
    collaborators: "/workspace/settings/collaborators",
    contracts: "/workspace/settings/contracts",
    delete: "/workspace/settings/delete",
    name: "/workspace/settings/name",
    security: "/workspace/settings/security",
    webhooks: "/workspace/settings/webhooks",
  },
  data: {
    propertySettings: "/workspace/data/property-settings",
  },
};

export default { workspaces: routes };
