import { AvatarProps as AntAvatarProps, Avatar as AntAvatar } from 'antd';
import React from 'react';

const Avatar: React.FC<AntAvatarProps> = (props) => {

  return (
    <AntAvatar {...props} />
  )
};

export default Avatar;