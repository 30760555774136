const routes = {
  activities: {
    all: "/identities/activities/all",
    search: (params: URLSearchParams) => {
      return `/identities/activities/all?${params.toString()}`;
    },
  },
  cohorts: {
    all: "/identities/cohorts/all",
    edit: (id: string) => {
      const params = new URLSearchParams({ id });

      return `/identities/cohorts?${params.toString()}`;
    },
    details: (id: string) => {
      const params = new URLSearchParams({ id });

      return `/identities/cohorts/details?${params.toString()}`;
    },
    home: (id: string) => {
      const params = new URLSearchParams({ id });

      return `/identities?${params.toString()}`;
    },
  },
  all: "/identities/all",
  home: (id: string) => {
    const params = new URLSearchParams({ id });

    return `/identities?${params.toString()}`;
  },
};

export default { identities: routes };
