import WorkspaceListItem from "@features/workspaces/WorkspaceListItem";
import React from "react";
import ListSkeleton from "@components/skeletons/ListSkeleton";
import Image from "next/image";
import { Text, Title } from "@components/Typography";
import { useAuth } from "@features/auth/useAuth";
import { useTranslation } from "react-i18next";
import { WorkspaceDetails } from "@features/workspaces/settings/System/hooks/useSystemSettings";

type Props = {
  isLoading: boolean;
  workspaces: WorkspaceDetails[];
  onSwitch: (w: WorkspaceDetails) => Promise<void>;
};

export const WorkspaceList: React.FC<Props> = ({ onSwitch, workspaces, isLoading }) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  if (isLoading || !user) return <ListSkeleton />;

  if (!workspaces?.length) {
    return (
      <div className="w-full h-60 flex flex-col items-center justify-center gap-2">
        <Image src="/assets/handy-line-surprised-monitor-with-a-book.svg" alt="no-notifications-image" width={100} height={100} />
        <Title level={5}>{t("No workspaces yet")}</Title>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-2 h-60 overflow-y-auto">
      {workspaces.map((workspace) => (
        <WorkspaceListItem
          key={workspace.id}
          isCurrent={user["workspaceId"] === workspace.id}
          workspace={workspace}
          onClick={() => onSwitch(workspace)}
        />
      ))}
    </div>
  );
};
