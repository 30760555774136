const routes = {
  workflows: {
    home: "/automations/workflows",
    templates: "/automations/workflows/templates",
    define: (id: string) => {
      const urlParams = new URLSearchParams({ id });
      return `/automations/workflows/define?${urlParams.toString()}`;
    },
  },
  workflowLegacy: {
    active: "/automations/workflows-legacy/active",
    create: "/automations/workflows-legacy/create",
    deleted: "/automations/workflows-legacy/deleted",
    paused: "/automations/workflows-legacy/paused",
    details: (id: string) => {
      const params = new URLSearchParams({ id });

      return `/automations/workflows-legacy/details?${params.toString()}`;
    },
    update: (id: string) => {
      const params = new URLSearchParams({ id });

      return `/automations/workflows-legacy/create?${params.toString()}`;
    },
  },
};

export default { automations: routes };
