import React from "react";
import AutomationsSidebar from "@components/PageWrapper/sidebars/AutomationsSidebar";
import WorkspaceSidebar from "@components/PageWrapper/sidebars/WorkspaceSidebar";
import DataManagementSidebar from "@components/PageWrapper/sidebars/DataManagementSidebar";
import SystemSidebar from "@components/PageWrapper/sidebars/SystemSidebar";

export enum SidebarComponents {
  Automations = "automations",
  Workspace = "workspace",
  System = "system",
  DataManagement = "data-management",
}
export type SidebarProps = {
  activeCategory?: SidebarComponents | string;
};
const Sidebar: React.FC<SidebarProps> = ({ activeCategory }) => {
  if (!activeCategory) return null;

  switch (activeCategory) {
    case SidebarComponents.Automations:
      return <AutomationsSidebar />;
    case SidebarComponents.Workspace:
      return <WorkspaceSidebar />;
    case SidebarComponents.DataManagement:
      return <DataManagementSidebar />;
    case SidebarComponents.System:
      return <SystemSidebar />;
    default:
      return null;
  }
};

export default Sidebar;
