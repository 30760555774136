import React from "react";
import { CloudOutlined, HomeOutlined, SettingOutlined, SisternodeOutlined, FileProtectOutlined } from "@ant-design/icons";
import Menu from "@components/Menu";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { SidebarComponents } from "@components/PageWrapper/sidebars/Sidebar.component";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import FeaturesRoutes from "@src/routes/routes";
import { isNullOrEmpty } from "@src/utils/utils";
import { useFeatures } from "@components/Features/contexts";
import { Features } from "@components/Features";

export type MainNavigationProps = {
  activeCategory?: SidebarComponents | string;
};

const MainNavigation: React.FC<MainNavigationProps> = ({ activeCategory = "" }) => {
  const { t } = useTranslation();
  const { isConditionSame } = useFeatures();
  const router = useRouter();

  const handleMenuClick = async (path: string) => {
    if (isNullOrEmpty(path)) {
      await router.push(FeaturesRoutes.home);

      return;
    }
    await router.push(path);
  };

  const menuItems: ItemType[] = [
    {
      key: "",
      label: t("Home"),
      icon: <HomeOutlined />,
      onClick: () => handleMenuClick(""),
    },
    isConditionSame(Features.EventSourcing) && {
      key: SidebarComponents.Automations,
      label: t("Automations"),
      icon: <SisternodeOutlined />,
      onClick: () => handleMenuClick(FeaturesRoutes.automations.workflows.home),
    },
    isConditionSame(Features.EventSourcing) && {
      key: SidebarComponents.DataManagement,
      label: t("Data management"),
      icon: <CloudOutlined />,
      onClick: () => handleMenuClick(FeaturesRoutes.dataManagement.activityStream.home),
    },
    {
      key: SidebarComponents.Workspace,
      label: t("Workspace settings"),
      icon: <SettingOutlined />,
      onClick: () => handleMenuClick(FeaturesRoutes.workspaces.settings.name),
    },
    {
      key: SidebarComponents.System,
      label: t("Admin"),
      icon: <FileProtectOutlined />,
      onClick: () => handleMenuClick(FeaturesRoutes.system.home),
    },
  ];

  return (
    <div className="bg-white border-r">
      <Menu selectedKeys={[activeCategory]} style={{ height: "100%", paddingTop: "1rem" }} mode="inline" inlineCollapsed items={menuItems} />
    </div>
  );
};

export default MainNavigation;
