import { Title } from "@components/Typography";
import Link from "next/link";
import React from "react";
import { MetacommerceLogoProps } from "./MetacommerceLogo.types";

const MetacommerceLogo: React.FC<MetacommerceLogoProps> = ({ href, color = "blue" }) => {
  const getLogoColors = () => {
    switch (color) {
      case "blue":
        return {
          background: "#1255ff",
          foreground: "white",
        };
      case "white":
        return {
          background: "white",
          foreground: "#1255ff",
        };
      default:
        return {
          background: "#1255ff",
          foreground: "white",
        };
    }
  };

  const { background, foreground } = getLogoColors();

  return (
    <Link href={href}>
      <div className="flex gap-3 cursor-pointer items-center">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 6C0 2.68629 2.68629 0 6 0H30V24C30 27.3137 27.3137 30 24 30H0V6Z" fill={background} />
          <path
            d="M8.29688 9.36364H12.2287L14.9332 15.9545H15.0696L17.7741 9.36364H21.706V21H18.6151V14.2727H18.5241L15.9332 20.9091H14.0696L11.4787 14.2273H11.3878V21H8.29688V9.36364Z"
            fill={foreground}
          />
        </svg>
        <Title level={4}>Metacommerce</Title>
      </div>
    </Link>
  );
};

export default MetacommerceLogo;
