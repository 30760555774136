const routes = {
  home: "/accountRecovery",
  complete: (email: string, isLoggedIn?: boolean) => {
    const params = new URLSearchParams({ emailAddress: email, isLoggedIn: isLoggedIn?.toString() });
    return `/accountRecovery/complete?${params.toString()}`;
  },
  confirmNewEmail: (email: string, isLoggedIn?: boolean) => {
    const params = new URLSearchParams({ emailAddress: email, isLoggedIn: isLoggedIn?.toString() });
    return `/accountRecovery/confirmNewEmail?${params.toString()}`;
  },
};

export default { recovery: routes };
