import React from "react";
import FeaturesRoutes from "@src/routes/routes";
import Menu from "@components/Menu";
import { Title } from "@components/Typography";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { useFeatures } from "@components/Features/contexts";

const WorkspaceSidebar: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isConditionSame } = useFeatures();

  // remove the forward slash in the beginning
  const activePage = router.route || FeaturesRoutes.workspaces.settings.name;

  const handleMenuClick = (info: { key: string }) => {
    router.push(info.key);
  };

  const menuItems = [
    {
      key: FeaturesRoutes.workspaces.settings.name,
      label: t("Workspace name"),
      onClick: handleMenuClick,
    },
    {
      key: FeaturesRoutes.workspaces.settings.collaborators,
      label: t("Collaborators"),
      onClick: handleMenuClick,
    },
    {
      key: FeaturesRoutes.workspaces.settings.apiKeys,
      label: t("API keys"),
      onClick: handleMenuClick,
    },
    {
      key: FeaturesRoutes.workspaces.settings.identities,
      label: t("Identity Settings"),
      onClick: handleMenuClick,
    },
    {
      key: FeaturesRoutes.workspaces.settings.uniqueIdentities,
      label: t("Unique Identity Settings"),
      onClick: handleMenuClick,
    },
  ];

  return (
    <div className="flex flex-col pt-10 font-sans gap-3">
      <Title className="px-5" level={4}>
        {t("Workspace")}
      </Title>
      <Menu selectedKeys={[activePage]} mode="inline" style={{ background: "white" }} items={menuItems} />
    </div>
  );
};

export default WorkspaceSidebar;
