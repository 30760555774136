import APIRoutes from "@src/routes/api/routes";
import { useQuery } from "@tanstack/react-query";
import { getLocalWorkspaceHeaders } from "@features/auth/useAuth";

type OrganizationSummary = {
  id: string;
  name: string;
};

export type WorkspaceDetails = {
  name: string;
  slug: string;
  id: string;
  organization: OrganizationSummary;
};

export const useSystemSettingsQuery = () => {
  return useQuery(["system,"], async () => {
    const res = await fetch(APIRoutes.workspaces.system.list, {
      headers: getLocalWorkspaceHeaders(),
    });

    if (!res.ok) {
      throw new Error(await res.text());
    }

    const settings = (await res.json()) as WorkspaceDetails[];

    return settings || [];
  });
};
