const routes = {
  activities: {
    templates: {
      create: "/data-management/activities/templates/create",
      update: (id: string) => {
        const params = new URLSearchParams({ id });

        return `/data-management/activities/templates?${params.toString()}`;
      },
      home: "/data-management/activities/templates",
    },
    home: "/data-management/activities",
  },
  properties: "/data-management/properties",
  tags: "/data-management/tags",
  identityStream: {
    home: "/data-management/identity-stream",
    identities: "/data-management/identity-stream/identities",
  },
  uniqueIdentityStream: {
    home: "/data-management/unique-identity-stream",
    uniqueIdentities: "/data-management/unique-identity-stream/unique-identities",
  },
  activityStream: {
    home: "/data-management/activity-stream",
    activities: "/data-management/activity-stream/activities",
  },
  // event sourcing
  sources: {
    home: "/data-management/sources",
    syncedContracts: "/data-management/sources/synced-contracts",
    fide: "/data-management/sources/fide",
    flipside: "/data-management/sources/flipside",
    crossmint: "/data-management/sources/crossmint",
    segment: "/data-management/sources/segment",
    static: {
      home: "/data-management/sources/static",
      csv: {
        home: "/data-management/sources/static/csv",
        create: "/data-management/sources/static/csv/create",
      },
    },
    twitter: "/data-management/sources/twitter",
    zealy: "/data-management/sources/zealy",
    salesforce: "/data-management/sources/salesforce",
    discord: "/data-management/sources/discord",
    alchemy: "/data-management/sources/alchemy",
  },
  destinations: {
    home: "/data-management/destinations",
    webhooks: {
      home: "/data-management/destinations/webhooks",
      create: "/data-management/destinations/webhooks/create",
    },
    mixpanel: {
      home: "/data-management/destinations/mixpanel",
      define: (id: string) => `/data-management/destinations/mixpanel/${id}`,
    },
    salesforce: {
      home: "/data-management/destinations/salesforce",
      define: (id: string) => `/data-management/destinations/salesforce/${id}`,
    },
    crossmint: {
      home: "/data-management/destinations/crossmint",
      define: (id: string) => `/data-management/destinations/crossmint/${id}`,
    },
    telegram: {
      home: "/data-management/destinations/telegram",
      define: (id: string) => `/data-management/destinations/telegram/${id}`,
    },
    google: {
      home: "/data-management/destinations/google",
      define: (id: string) => `/data-management/destinations/google/${id}`,
    },
    slack: {
      home: "/data-management/destinations/slack",
      define: (id: string) => `/data-management/destinations/slack/${id}`,
    },
    twitter: {
      home: "/data-management/destinations/twitter",
      define: (id: string) => `/data-management/destinations/twitter/${id}`,
    },
    zapier: {
      home: "/data-management/destinations/zapier",
      define: (id: string) => `/data-management/destinations/zapier/${id}`,
    },
    klaviyo: {
      home: "/data-management/destinations/klaviyo",
      define: (id: string) => `/data-management/destinations/klaviyo/${id}`,
    },
    sendgrid: {
      home: "/data-management/destinations/sendgrid",
      define: (id: string) => `/data-management/destinations/sendgrid/${id}`,
    },
  },
};

export default { dataManagement: routes };
