import { CheckOutlined } from "@ant-design/icons";
import Avatar from "@components/Avatar";
import Spinner from "@components/spinners/Spinner";
import { Text } from "@components/Typography";
import { PRIMARY_COLOR } from "@src/services/theme/colors";
import React, { useState } from "react";
import { WorkspaceDetails } from "@features/workspaces/settings/System/hooks/useSystemSettings";

export interface WorkspaceListItemProps {
  workspace: WorkspaceDetails;
  onClick: () => Promise<void>;
  isCurrent?: boolean;
  noIsSelected?: boolean;
}

const WorkspaceListItem: React.FC<WorkspaceListItemProps> = ({ workspace, onClick, isCurrent, noIsSelected }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  return (
    <div
      onClick={handleClick}
      key={workspace.id}
      className="w-full py-2 px-2 flex gap-2 items-center cursor-pointer bg-white hover:bg-[#f8fafc] transition"
    >
      {noIsSelected ? null : <div className="w-[5%]">{isCurrent ? <CheckOutlined style={{ color: PRIMARY_COLOR }} /> : null}</div>}
      <div>
        <Avatar>
          {
            // check if this workspace is currently being loaded, if so, show spinner
            loading ? <Spinner size="small" /> : workspace.name?.charAt(0).toUpperCase()
          }
        </Avatar>
      </div>
      <div className="flex flex-col w-[70%]">
        <Text ellipsis={{ tooltip: workspace.name }}>{workspace.name}</Text>
      </div>
    </div>
  );
};

export default WorkspaceListItem;
