import { Text } from "@components/Typography";
import { Menu as AntMenu } from "antd";
import { useRouter } from "next/router";
import React from "react";
import { MenuItemProps, MenuProps } from "./Menu.types";

const Menu: React.FC<MenuProps> = (props) => {
  return <AntMenu {...props} />;
};

export const MenuItem: React.FC<MenuItemProps> = ({ to, title, suffixIcon, onClick }) => {
  const router = useRouter();

  const handleClick = () => {
    if (onClick) onClick();
    if (to) router.push(to);
  };

  return (
    <div className="cursor-pointer w-full py-2 px-1 flex justify-between items-center" onClick={handleClick}>
      <Text className="hover:text-sky-500 transition-all">{title}</Text>
      {suffixIcon}
    </div>
  );
};

export default Menu;
